import axios from "axios";
const token = localStorage.getItem("alyusrtoken");
export const URL = "http://172.19.105.154/alyusr_backend/public/api/admin";

const URLAPPS = "https://alyusr.appssquare.com/api/admin";

const instance = axios.create({
  baseURL: `${URLAPPS}`,
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});

instance.interceptors.request.use((req) => {
  // req?.data.append('_method','put')

  //req.params['_method']='put'

  // Important: request interceptors **must** return the request.
  return req;
});
instance.interceptors.response.use(
  (res) => {
    return res;
  },

  (err) => {
    if (
      err &&
      err.response &&
      err.response.status == 403 &&
      err.response.config.url != "admin/login"
    ) {
      window.localStorage.removeItem("alyusrtoken");

      window.location.replace("/");
    } else if (
      err.response.status == 401 &&
      err.response.config.url != "admin/login"
    ) {
      // window.localStorage.removeItem("alyusrtoken");
      // window.location.replace("/");
    }

    // Important: response interceptors **must** return the response.
    // return err;
    else return Promise.reject(err);
  }
);

export default instance;
